<template>
    <AppLayout :title="$t('homepage.slogan')">
        <main class="isolate">
            <!-- Hero -->
            <section class="relative isolate h-screen--menu overflow-hidden">
                <div aria-hidden="true" class="absolute inset-0 overflow-hidden">
                    <img src="../../images/landing.jpg" class="h-full w-full object-cover object-center" />
                </div>
                <div aria-hidden="true" class="absolute inset-0 bg-black opacity-50" />

                <div class="h-screen--menu sm:flex sm:items-center">
                    <div
                        class="relative mx-auto flex max-w-4xl flex-col items-center px-12 py-20 text-center sm:py-0 lg:px-0"
                    >
                        <img src="../../images/clawmee-badge.svg" class="mx-auto w-80 rotate-[15deg] opacity-70" />
                        <p class="mt-10 text-2xl text-cx-pink md:text-5xl">
                            {{ $t('homepage.description') }}
                        </p>
                        <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLSeA17AhrCuUkP2a4xV2h39NEeUY7DGmsLjQGgLLVwq-JtwZyw/viewform"
                            target="_blank"
                            class="btn-solid-primary btn-xl mt-8"
                        >
                            {{ $t('homepage.cta') }}
                        </a>
                    </div>
                </div>
            </section>

            <!-- About Us -->
            <section id="about-us" class="overflow-hidden py-32 sm:py-40">
                <div class="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
                    <div
                        class="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8"
                    >
                        <div class="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
                            <h2 class="mt-8 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                                {{ $t('app.about_us') }}
                            </h2>
                            <p class="my-6 text-base leading-7 text-gray-600">
                                {{ $t('homepage.about_us_description') }}
                            </p>
                        </div>
                        <div class="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
                            <div class="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
                                <img
                                    src="../../images/about-1.jpg"
                                    class="aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover"
                                />
                            </div>
                            <div
                                class="contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8"
                            >
                                <div class="order-first flex w-64 flex-none justify-end self-end lg:w-auto">
                                    <img
                                        src="../../images/about-2.jpg"
                                        class="aspect-[4/3] w-[24rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover"
                                    />
                                </div>
                                <div class="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
                                    <img
                                        src="../../images/about-3.jpg"
                                        class="aspect-[7/5] w-[37rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover"
                                    />
                                </div>
                                <div class="hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none">
                                    <img
                                        src="../../images/about-4.jpg"
                                        class="aspect-[4/3] w-[24rem] max-w-none rounded-2xl bg-gray-50 object-cover"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- How to play -->
            <section id="how-to-play" class="relative isolate overflow-hidden bg-gray-900">
                <svg
                    class="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                    aria-hidden="true"
                >
                    <defs>
                        <pattern
                            id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
                            width="200"
                            height="200"
                            x="50%"
                            y="-1"
                            patternUnits="userSpaceOnUse"
                        >
                            <path d="M.5 200V.5H200" fill="none" />
                        </pattern>
                    </defs>
                    <svg x="50%" y="-1" class="overflow-visible fill-gray-800/20">
                        <path
                            d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                            stroke-width="0"
                        />
                    </svg>
                    <rect
                        width="100%"
                        height="100%"
                        stroke-width="0"
                        fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)"
                    />
                </svg>
                <div
                    class="absolute left-[calc(50%-4rem)] top-10 -z-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]"
                    aria-hidden="true"
                >
                    <div
                        class="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-[#ffbce8] to-[#641775] opacity-20"
                        style="
                            clip-path: polygon(
                                73.6% 51.7%,
                                91.7% 11.8%,
                                100% 46.4%,
                                97.4% 82.2%,
                                92.5% 84.9%,
                                75.7% 64%,
                                55.3% 47.5%,
                                46.5% 49.4%,
                                45% 62.9%,
                                50.3% 87.2%,
                                21.3% 64.1%,
                                0.1% 100%,
                                5.4% 51.1%,
                                21.4% 63.9%,
                                58.9% 0.2%,
                                73.6% 51.7%
                            );
                        "
                    />
                </div>

                <div class="mx-auto max-w-2xl px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8">
                    <div class="grid grid-cols-1 items-center gap-x-8 gap-y-16 lg:grid-cols-2">
                        <div>
                            <div class="pb-10">
                                <p class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
                                    {{ $t('app.how_to_play') }}
                                </p>
                                <h2 class="font-medium text-gray-500">{{ $t('homepage.how_to_play.title') }}</h2>
                            </div>

                            <dl class="mt-10 space-y-10">
                                <div v-for="instruction in instructions" :key="instruction.name">
                                    <dt class="font-medium text-white">{{ instruction.name }}</dt>
                                    <dd class="mt-3 whitespace-pre-line text-gray-400">
                                        {{ instruction.description }}
                                    </dd>
                                </div>
                            </dl>
                        </div>

                        <div>
                            <div class="grid grid-cols-2 gap-4 sm:mt-6 sm:gap-6 lg:mt-8 lg:gap-8">
                                <div class="aspect-square overflow-hidden rounded-lg bg-gray-100">
                                    <img
                                        src="../../images/insert-coin.jpg"
                                        class="h-full w-full object-cover object-center"
                                    />
                                </div>
                                <div class="aspect-square overflow-hidden rounded-lg bg-gray-100">
                                    <img
                                        src="../../images/play-claw-machine.jpg"
                                        class="h-full w-full object-cover object-center"
                                    />
                                </div>
                            </div>
                            <div class="mt-4 aspect-[2/1] overflow-hidden rounded-lg bg-gray-100">
                                <img
                                    src="../../images/plushies-on-bench.jpg"
                                    class="h-full w-full object-cover object-center"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- FAQ -->
            <section id="faq" class="relative isolate -z-10">
                <div class="overflow-hidden">
                    <div class="mx-auto max-w-7xl px-6 pb-32 pt-36 sm:pt-60 lg:px-8 lg:pt-32">
                        <div class="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
                            <div class="w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
                                <h1 class="mb-10 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                                    {{ $t('app.faq') }}
                                </h1>

                                <div class="mt-10">
                                    <h2 class="text-base font-semibold leading-7 text-gray-900">
                                        {{ $t('homepage.faq.title') }}
                                    </h2>
                                    <ul role="list" class="grid sm:grid-cols-2">
                                        <li
                                            v-for="product in products"
                                            :key="product.id"
                                            class="flex items-center gap-x-4 py-5"
                                        >
                                            <div class="aspect-square w-24 overflow-hidden">
                                                <img
                                                    :src="product.image"
                                                    :alt="product.name"
                                                    class="h-full w-full object-cover object-center"
                                                />
                                            </div>

                                            <div class="min-w-0">
                                                <p class="text-xl font-semibold text-gray-900">
                                                    {{ product.name }}
                                                </p>
                                                <p class="mt-1 truncate text-lg text-gray-600">
                                                    {{ $n(product.display_price, 'currency') }}
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <div class="mt-10">
                                    <h2 class="text-base font-semibold leading-7 text-gray-900">
                                        {{ $t('homepage.faq.question') }}
                                    </h2>

                                    <p class="mt-2 text-base leading-7 text-gray-600">
                                        {{ $t('homepage.faq.answer') }}
                                    </p>
                                </div>
                            </div>
                            <div
                                class="mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0"
                            >
                                <div
                                    class="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80"
                                >
                                    <div class="relative">
                                        <img
                                            src="../../images/faq-1.jpg"
                                            class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                        />
                                        <div
                                            class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10"
                                        />
                                    </div>
                                </div>
                                <div class="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
                                    <div class="relative">
                                        <img
                                            src="../../images/faq-2.jpg"
                                            class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                        />
                                        <div
                                            class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10"
                                        />
                                    </div>
                                    <div class="relative">
                                        <img
                                            src="../../images/faq-3.jpg"
                                            class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                        />
                                        <div
                                            class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10"
                                        />
                                    </div>
                                </div>
                                <div class="w-44 flex-none space-y-8 pt-32 sm:pt-0">
                                    <div class="relative">
                                        <img
                                            src="../../images/faq-4.jpg"
                                            class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                        />
                                        <div
                                            class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10"
                                        />
                                    </div>
                                    <div class="relative">
                                        <img
                                            src="../../images/faq-5.jpg"
                                            class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                        />
                                        <div
                                            class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </AppLayout>
</template>

<script setup>
import { Link } from '@inertiajs/vue3';
import AppLayout from '@/Layouts/AppLayout/Layout.vue';
import { translate as t } from '@/i18n';

defineProps({
    products: Object,
});

const instructions = [
    {
        name: t('homepage.how_to_play.buy_the_tokens.name'),
        description: t('homepage.how_to_play.buy_the_tokens.description'),
    },
    {
        name: t('homepage.how_to_play.play_and_win.name'),
        description: t('homepage.how_to_play.play_and_win.description'),
    },
    {
        name: t('homepage.how_to_play.keep_or_trade.name'),
        description: t('homepage.how_to_play.keep_or_trade.description'),
    },
];
</script>
